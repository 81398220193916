import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, Button, Flex, Icon, Text, LoadingAnimation } from "@nulogy/components";
import { CODE_WIDTH, COPY_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Loading content`}</h1>
    <p>{`Loading patterns are used when a user’s action causes a delay in the system’s response. A successful loading indicator provides timely feedback on the progress of the user’s request and creates an illusion of time going by faster.`}</p>
    <Box width="100%" textAlign="center" my="x8" mdxType="Box">
  <LoadingAnimation mdxType="LoadingAnimation" />
    </Box>
    <p>{`Nulogy Design System provides a range of loading methods to match various situations.`}</p>
    <h2>{`Skeleton screens (progressive loading)`}</h2>
    <p>{`The skeleton screens deliver information in increments as they are becoming available, making the loading experience fluid and seamless. The skeleton screens are used for page or section loads and typically complete within 5 seconds, even though the duration of 10 seconds is acceptable. For delays over 10 seconds, other methods that provide more feedback should be considered.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1024px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/de55f/skeleton-screen.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABSElEQVQY01VQSy9DQRS+v8/GP5BI8AcQllYaiZUKsaBeG7eJXSVi5RVpEVFSEoQirWcr0TvnOXeYe7X4MjnnzEm+R04ws7g2NZ/PLoTZhfzs8vro+HR373BXz2DfUGZzu7i1U9orlQ+OzneLp/WXd+dcHMeujaAzpdtG8+Pk7PK0cl2+uDk4Pt8/LI9Nzg2MTPQPZzLTK6vrm7mwsBRu5NYKubAQIDMSi6iosmjs4UStl0tE396bdw+159dG7en19r5WfazfPvhauaoGSEwsqmoTEEnLoGhsE5U0jqpNh9/EzhmQgFiQBJCRxAACkoioWhHru2oEhCT2L8+5T3ARsHcGZEBC4sggABElX79kA5hEY/FQZkkjALsIyDun5kn9ETJAnonUisCLJomIxSB2rm2AAvcfcRyL2uSpv6IoMkcGOtFsm/zt/AWouLi6D8/zggAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "skeleton screen",
          "title": "skeleton screen",
          "src": "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/2bef9/skeleton-screen.png",
          "srcSet": ["/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/6f3f2/skeleton-screen.png 256w", "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/01e7c/skeleton-screen.png 512w", "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/2bef9/skeleton-screen.png 1024w", "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/71c1d/skeleton-screen.png 1536w", "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/a878e/skeleton-screen.png 2048w", "/static/bc4d4fc5ba61bb2d9b5f8d8f3c8933d4/de55f/skeleton-screen.png 3080w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h2>{`Full-screen loading`}</h2>
    <p>{`The full-screen method is used when an action temporarily disables the application for more than 2 seconds. There are 2 types of full-screen loaders: `}<strong parentName="p">{`Indeterminate`}</strong>{` and `}<strong parentName="p">{`Determinate`}</strong>{`.`}</p>
    <p>{`The indeterminate loading method is used for actions that can't be estimated, unlike determinate loading which is used for actions that can be estimated.`}</p>
    <p>{`For delays between 2 and 5 seconds the animation is sufficient, while for delays over 5 seconds the animation should be accompanied with the appropriate combination of information and actions. If the action takes longer than expected to execute, a warning with options to retry or abort should be displayed.`}</p>
    <Flex width="100%" maxWidth={CODE_WIDTH} mt="x8" mb="x6" mx="auto" mdxType="Flex">
  <Box width="240px" mx="auto" mdxType="Box">
    <Text mb="x1" textAlign="right" mr="x3" fontWeight="medium" mdxType="Text">
      2/4
    </Text>
    <LoadingAnimation mdxType="LoadingAnimation" />
    <Box mt="x1" mx="x3" mdxType="Box">
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Data loaded
        </Text>
        <Icon icon="check" color="green" height="20px" mdxType="Icon" />
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" fontWeight="medium" mdxType="Text">
          Applying status ...
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Change price
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Update status
        </Text>
      </Flex>
    </Box>
    <Flex justifyContent="center" mt="x8" mdxType="Flex">
      <Icon color="grey" icon="refresh" size="40px" mr="x1" p="x1" mdxType="Icon" />
      <Icon color="darkGrey" icon="close" size="40px" p="x1" mdxType="Icon" />
    </Flex>
  </Box>
  <Box width="360px" mx="auto" mdxType="Box">
    <Text mb="half" mt="x2" textAlign="right" color="darkGrey" fontSize="smaller" mdxType="Text">
      about <strong>21 sec.</strong> left
    </Text>
    <Box height="8px" width="100%" bg="lightGrey" mt="x1" mb="x2" position="relative" mdxType="Box">
      <Box position="absolute" top="0" left="0" width="100px" height="8px" bg="blue" mdxType="Box" />
    </Box>
    <Box mt="x1" mdxType="Box">
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Data loaded
        </Text>
        <Icon icon="check" color="green" height="20px" mdxType="Icon" />
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" fontWeight="medium" mdxType="Text">
          Applying status ...
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Change price
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Update status
        </Text>
      </Flex>
    </Box>
    <Flex justifyContent="center" mt="x8" mdxType="Flex">
      <Icon color="grey" icon="refresh" size="40px" mr="x1" p="x1" mdxType="Icon" />
      <Icon color="darkGrey" icon="close" size="40px" p="x1" mdxType="Icon" />
    </Flex>
  </Box>
    </Flex>
    <Text fontSize="small" color="darkGrey" textAlign="center" mt="x2" mdxType="Text">
  Example of indeterminate (left) and determinate (right) loading pattern with
  task/time counter, task list, and controls
    </Text>
    <h2>{`Progress widget`}</h2>
    <p>{`A progress Widget is used for actions that take at least 5 seconds and that do not need to be completed before another action can be performed. In this method, the progress bar is displayed in a modal window that can be minimized. There can be multiple modal windows active at the same time.`}</p>
    <Flex mt="x8" mb="x6" width="100%" maxWidth={CODE_WIDTH} mx="auto" justifyContent="space-between" alignItems="flex-end" mdxType="Flex">
  <Box width="360px" borderRadius="medium" boxShadow="large" pt="half" pr="x2" pb="x2" pl="x2" mdxType="Box">
    <Flex justifyContent="flex-end" position="relative" left="x1" mdxType="Flex">
      <Icon color="grey" icon="refresh" size="40px" mr="half" mb="x4" p="x1" mdxType="Icon" />
      <Icon color="darkGrey" icon="close" size="40px" mr="half" p="x1" mdxType="Icon" />
      <Icon color="darkGrey" icon="minimize" size="40px" p="x1" mdxType="Icon" />
    </Flex>
    <Flex justifyContent="space-between" mb="half" alignItems="flex-end" mdxType="Flex">
      <Text fontWeight="bold" m="0" mdxType="Text">
        Updating records
      </Text>
      <Text color="darkGrey" m="0" pb="half" fontSize="smaller" mdxType="Text">
        about <strong>21 sec.</strong> left
      </Text>
    </Flex>
    <Box height="8px" width="100%" bg="lightGrey" mt="x1" mb="x2" position="relative" mdxType="Box">
      <Box position="absolute" top="0" left="0" width="100px" height="8px" bg="blue" mdxType="Box" />
    </Box>
    <Box mt="x1" mdxType="Box">
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Data loaded
        </Text>
        <Icon icon="check" color="green" height="20px" mdxType="Icon" />
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" fontWeight="medium" mdxType="Text">
          Applying status ...
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Change price
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mb="half" mdxType="Flex">
        <Text m="0" fontSize="small" color="darkGrey" mdxType="Text">
          Update status
        </Text>
      </Flex>
    </Box>
  </Box>
  <Flex height="56px" borderRadius="medium" boxShadow="medium" alignItems="center" py="half" px="x2" mdxType="Flex">
    <Flex alignItems="center" mdxType="Flex">
      <Text m="0" mr="x2" mdxType="Text">
        Updating rec...
      </Text>
      <Text color="darkGrey" m="0" mr="half" pt="half" fontSize="smaller" mdxType="Text">
        <strong>21</strong> sec.
      </Text>
    </Flex>
    <Box position="relative" left="x1" height="40px" mdxType="Box">
      <Icon color="darkGrey" icon="more" size="40px" mr="half" p="x1" mdxType="Icon" />
      <Icon color="darkGrey" icon="maximize" size="40px" p="x1" mdxType="Icon" />
    </Box>
  </Flex>
    </Flex>
    <Text fontSize="small" color="darkGrey" textAlign="center" mt="x2" mdxType="Text">
  Example of maximized (left) and minimized (right) progress widget
    </Text>
    <h2>{`Inline loading`}</h2>
    <p>{`This method is should be avoided whenever possible since it provides an inferior user experience and adds additional technical complexity. This method should only be considered in combination with skeleton screens (when they are applied on a section of the page) and in legacy applications where this method is established as a standard. This method is used for actions that take between 2 and 10 seconds to execute.`}</p>
    <Flex maxWidth={COPY_WIDTH} m="0 auto" justifyContent="space-around" alignItems="center" mdxType="Flex">
  <Button mdxType="Button">Apply status</Button>
  <Flex alignItems="center" mt="x4" mb="x4" mdxType="Flex">
    <Text m="0" mr="x1" color="darlGrey" mdxType="Text">
      Applying status
    </Text>
    <Icon icon="loading" mdxType="Icon" />
  </Flex>
  <Flex alignItems="center" mdxType="Flex">
    <Icon icon="check" color="green" mr="x1" mdxType="Icon" />
    <Text m="0" color="darlGrey" mdxType="Text">
      Status applied
    </Text>
  </Flex>
    </Flex>
    <Text fontSize="small" color="darkGrey" textAlign="center" mt="x2" mdxType="Text">
  Button example of inline loading
    </Text>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      